import  * as React from 'react';
import {PureComponent} from 'react';
import {getObjectData} from "../../utils/getObjectData";
import {pdf} from "@react-pdf/renderer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//utils
import {returnBootstrapColor} from "../../utils/returnBootstrapColor";

//redux
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import {fetchClsData} from "../../redux/actions/cls/clsAction";
import {fetchSymptPrintData} from "../../redux/actions/pdf/sypmtPrintAction";

//components
import {MyDoc} from "../pdf";
import AdminPageConstructor from '../_commonComponents/pageConstructors/admin/adminPageConstructor/index';
import AddNewCenterForm from './components/AddNewCenterForm';
import EditCenterForm from './components/EditCenterForm';

//constants
import {MOBILE} from "../../config/constants";
const LIMIT = 20;
const FILTER = [{name:"path", value:"CENTERS"}];

/**
 * Centers - Центры '/admin/centers'
 */
class Centers extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            pdf: null
        }
    }

    componentDidMount() {
        this.isMobile();
        window.addEventListener('resize', this.isMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.isMobile);
    }

    /**
     * isMobile
     */
    isMobile = () => {
        const isMobile = window.innerWidth < MOBILE;
        this.setState({
            isMobile
        })
    };

    onFilter = (values) => {
        let filter = [...FILTER];

        for (let key in values) {
            if (values.hasOwnProperty(key)) {
                if (key === 'tag') {
                    filter.push({name: key, value: values[key]});
                } else {
                    filter.push({name: key, value: `%${values[key]}`, compare: 'LIKE'});
                }
            }
        }

        return filter;
    };

    /**
     * createPDF если понадобиться загружать данные для всего центра, нужно будет изменить данные с бэка
     */
    createPDF = async (data) => {
        let i = 0;

        while (i < data.length) {
            // eslint-disable-next-line no-await-in-loop
            await pdf(<MyDoc data={[data[i]]} />)
                .toBlob()
                // eslint-disable-next-line no-loop-func
                .then(blobProp => {
                    const url = URL.createObjectURL(blobProp);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = i;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });

            i += 1;
        }
    };

    /**
     * downloadPdf если понадобиться загружать данные для всего центра, нужно будет изменить данные с бэка
     */
    downloadPdf = async (info) => {
        const {
            fetchSymptPrintData
        } = this.props;

        const filter = [{
            name: 'center', value: info.original.name
        }];

        await fetchSymptPrintData(filter);

        this.setState({
            pdf: getObjectData(() => this.props.pdf.data).splice(0, 15)
        }, () => {
            this.createPDF(this.state.pdf)
        });
    };

    renderCell = (info) => (
        <div className="d-flex justify-content-end">
            <div style={{cursor: 'pointer'}} className="mr-4" onClick={() => this.openEditPopup(info.original)}>
                <FontAwesomeIcon
                    color={returnBootstrapColor('primary', this.props.theme)}
                    icon={"pencil-alt"}
                />
            </div>
            <div style={{cursor: 'pointer'}} onClick={() => this.openDeletePopup(info.original)}>
                <FontAwesomeIcon
                    color={returnBootstrapColor('danger', this.props.theme)}
                    icon={"trash"}
                />
            </div>
        </div>
    );

    /**
     * setColumns
     * renderCell {function}
     * return Array
     */
    setColumns = (renderCell) => {
        const {
            isMobile
        } = this.state;

        return [
            {
                Header: '',
                accessor: 'name',
                id: 'name',
                placeholder: 'Номер',
                filterable: false,
                sortable: false,
                style: {minWidth: isMobile ? 0 : 254},
                width: isMobile ? '100%' : 254,
                maxWidth: isMobile ? '100%' : 254,
                minWidth: isMobile ? 0 : 254
            },
            {
                ...!isMobile && {
                    Header: '',
                    accessor: 'title',
                    id: 'title',
                    placeholder: 'Название',
                    filterable: false,
                    sortable: false,
                    style:{ whiteSpace: 'unset'}
                }
            },
            {
                ...!isMobile && {
                    Header: '',
                    accessor: 'tag',
                    id: 'tag',
                    placeholder: 'GMT',
                    compare: '=',
                    filterable: false,
                    sortable: false,
                    style:{ textAlign: 'center', minWidth: 100},
                    width: 100,
                    maxWidth: 100,
                    minWidth: 100
                }
            },
            {
                Header: '',
                accessor: 'action',
                filterable: false,
                sortable: false,
                Cell: renderCell,
                style: {minWidth: 80},
                width: 80,
                minWidth: 80
            }
        ]
    };

    render() {
        const {
            data,
            total,
            formData,
            fetchClsData,
            parent,
            isError,
            msg,
            history
        } = this.props;

        const {
            isMobile,
        } = this.state;

        return (
            <AdminPageConstructor
                data={data}
                total={total}
                formData={formData}
                isMobile={isMobile}
                fetchData={fetchClsData}
                AddForm={AddNewCenterForm}
                EditForm={EditCenterForm}
                initFilter={FILTER}
                renderColumns={this.setColumns}
                title={"Центры"}
                titleIcon={"hospital"}
                parent={parent}
                actionIcon={"hospital"}
                isError={isError}
                msg={msg}
                modalTitle={"центр"}
                limit={LIMIT}
                history={history}
                onFilter={this.onFilter}
            />
        )
    }
}

const mapStateToProps = (store) => ({
    pdf: store.pdf.data,
    data: getObjectData(() => store.cls.data.data),
    parent: getObjectData(() => store.cls.data.data[0].parent),
    total: getObjectData(() => store.cls.data.total),
    formData: getObjectData(() => store.cls.formData),
    isError: store.cls.isError,
    msg: store.cls.message,
    theme: store.settings.theme
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchClsData,
    fetchSymptPrintData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Centers);
