import React, {PureComponent, Fragment} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getObjectData} from "../../utils/getObjectData";
import {pdf} from "@react-pdf/renderer";
import {EVENTS_STATUS_OBJECT, GET} from "../../config/constants";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchSymptPrintData} from "../../redux/actions/pdf/sypmtPrintAction";
import {fetchWorseningData} from "../../redux/actions/subjectSymp/subjectWorseningAction";
import {fetchAntipyreticData} from "../../redux/actions/subjectSymp/subjectAntipyreticAction";
import {fetchAdminSubjectData} from "../../redux/actions/admin/adminSubjectAction";
import {fetchSyncData} from "../../redux/actions/syncDataAction";

//components
import {MyDoc} from '../pdf/index';
import Loading from '../_commonComponents/Loading';
import AdminMonitorSubConstructor from '../_commonComponents/pageConstructors/subject/adminMonitorSubConstructor/index';
import Notification from '../../utils/showNotification';

//constants
import {WORSENING_OBJECT, ANTIPYRETIC_OBJECT} from "../../config/constants";

/**
 * AdminUsersSub Пациенты '/admin/users-sub'
 */
class AdminSub extends PureComponent {
    notification = Object.create(Notification);

    state = {
        component: null,
        worseningData: null,
        antipyreticData: null,
        isLoading: false
    };

    /**
     * onClick
     * @param info {Object}
     */
    onClick = (info) => {
        this.setState({
           isLoading: true
        });
        try {
            this.getPdfData(info.id)
                .then(() => {
                pdf(<MyDoc
                    data={getObjectData(() => this.props.pdf.data)}
                    tableData={this.props.tableData}
                    worseningData={this.props.worseningData}
                    antipyreticData={this.props.antipyreticData}
                />)
                    .toBlob()
                    .then(blob => {
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `MMH407001_${info.num}`;
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }).then(() => {
                    this.setState({
                        isLoading: false
                    });
                }).catch(e => console.error(e));
            });
        } catch (error) {
            console.error(error)
        }
    };

    /**
     * getPdfData
     * @param id {number}
     * @return {Promise<void>}
     */
    getPdfData = async (id) => {
        const {
            fetchSymptPrintData,
            fetchAdminSubjectData,
            fetchWorseningData,
            fetchAntipyreticData
        } = this.props;

        const filter = [{
            name: "id", value: id
        }];

        await fetchSymptPrintData(filter);
        await fetchAdminSubjectData(null, EVENTS_STATUS_OBJECT, [{name: "sub", value: id}], 'events');
        await fetchAntipyreticData(GET, {}, [{name:"sub",value:id}], true, ANTIPYRETIC_OBJECT);
        await fetchWorseningData(GET, {}, [{name:"sub",value:id}], true, WORSENING_OBJECT);
    };

    /**
     * renderCell {Object}
     * @param info
     * @return {*}
     */
    renderCell = (info) => {
        return (
            <div className="d-flex justify-content-end">
                <div style={{cursor: 'pointer', fontSize: 24, lineHeight: 1}} className="mr-3">
                    <FontAwesomeIcon
                        color={getObjectData(() => info.original.checked_color)}
                        icon={"user-md"}
                    />
                </div>
                <div style={{cursor: 'pointer', fontSize: 24, lineHeight: 1}} className="mr-3">
                    <FontAwesomeIcon
                        color={getObjectData(() => info.original.verified_color)}
                        icon={"user-astronaut"}
                    />
                </div>
                <div
                    style={{cursor: 'pointer', fontSize: 24, lineHeight: 1}}
                    onClick={() => this.onClick(info.original)}>
                    <FontAwesomeIcon
                        color={'#B6B9C9'}
                        icon={"file-pdf"}
                    />
                </div>
            </div>
        )
    };

    syncAction = () => {
        const {
            fetchSyncData
        } = this.props;

        fetchSyncData().then(
            () => {
                this.notification.show('Данные успешно синхронизированы', 'Успешно!', 'success');
            },
            (e) => {
                this.notification.show(`${e}`);
            }
        );
    };

    render() {
        const {
            history,
            total
        } = this.props;

        const {
            isLoading
        } = this.state;

        return (
            <Fragment>
                {isLoading && <Loading/>}
                <AdminMonitorSubConstructor
                    renderCell={this.renderCell}
                    history={history}
                    syncAction={this.syncAction}
                    syncIcon={"download"}
                    headerTotal={total}
                />
                {this.state.component ? this.state.component : null}
            </Fragment>
         );
    }
}

const mapStateToProps = (store) => ({
    pdf: store.pdf.data,
    tableData: getObjectData(() => store.adminSubject.eventData.data),
    antipyreticData: getObjectData(() => store.antipyreticData.data.data) || [],
    worseningData: getObjectData(() => store.worseningData.data.data) || [],
    total: getObjectData(() => store.adminSubject.data.total),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSymptPrintData,
    fetchWorseningData,
    fetchAntipyreticData,
    fetchAdminSubjectData,
    fetchSyncData
},dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminSub);
