import React from 'react';
import {Document, Page, Text, View, StyleSheet, Font, Image} from '@react-pdf/renderer';
import fontRegular from './fonts/Roboto-Regular.ttf';
import fortBold from './fonts/Roboto-Bold.ttf';
import check from './images/check-circle-regular.jpg';
import times from './images/times-circle-regular.jpg';
import file_grey from './images/file-alt-regular-grey.jpg';
import file_green from './images/file-alt-regular-green.jpg';
import smile from './images/smile.jpg';
import {getObjectData} from "../../utils/getObjectData";

Font.register({ family: 'Roboto-regular', src: fontRegular});
Font.register({ family: 'Roboto-bold', src: fortBold});

Font.registerHyphenationCallback(word => {
    const middle = Math.floor(word.length / 2);
    const parts = word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];
    return parts;
});

const styles = StyleSheet.create({
    page: {
        padding: '50',
    },
    text: {
        fontSize: 13,
        fontFamily: 'Roboto-bold',
        color: '#646777',
        fontWeight: 'bold'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: '15',
        borderBottomWidth: '1',
        borderBottomStyle: 'solid',
        borderBottomColor: '#eff1f5',
        fontSize: 14
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        borderBottomWidth: '1',
        borderBottomStyle: 'solid',
        borderBottomColor: '#eff1f5',
        borderLeftWidth: 1,
        borderLeftStyle: 'solid',
        borderLeftColor: '#eff1f5',
    },
    tableCell: {
        fontSize: 12,
        padding: '10',
        fontFamily: 'Roboto-regular',
        color: '#646777',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        width: '33.3%',
    },
    tableCellHeader: {
        fontSize: 12,
        padding: '10',
        fontFamily: 'Roboto-bold',
        color: '#646777',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        width: '33.3%',
        fontWeight: 'bold',
        lineHeight: '1.5',
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: '#eff1f5'
    },
    tableCellHeaderText: {
        fontSize: 12,
        fontFamily: 'Roboto-regular',
        color: '#646777',
        lineHeight: '1.5'
    },
    tableCellHeaderTextBold: {
        fontSize: 12,
        fontFamily: 'Roboto-bold',
        color: '#646777',
        fontWeight: 'bold',
    },
    symptTableCell: {
        fontSize: 12,
        padding: '10',
        fontFamily: 'Roboto-regular',
        color: '#646777',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        display: 'block',
        flexShrink: 1,
        flexGrow: 1,
        flexBasis: 'auto'
    },
    symptTableCellHeader: {
        fontSize: 12,
        padding: '10',
        fontFamily: 'Roboto-bold',
        color: '#646777',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        flexShrink: 1,
        flexGrow: 1,
        flexBasis: 'auto',
        fontWeight: 'bold'
    },
    symptTableCellIndex: {
        fontSize: 12,
        flexGrow: 0,
        minWidth: 50,
        maxWidth: 50,
        padding: '10',
        fontFamily: 'Roboto-regular',
        color: '#646777',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        overflowWrap: 'break-word'
    },
    symptTableCellDate: {
        fontSize: 12,
        flexGrow: 0,
        minWidth: 105,
        maxWidth: 105,
        padding: '10',
        fontFamily: 'Roboto-regular',
        color: '#646777',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        overflowWrap: 'break-word'
    },
    symptTableCellDateHeader: {
        fontSize: 12,
        flexGrow: 0,
        minWidth: 105,
        maxWidth: 105,
        padding: '10',
        fontFamily: 'Roboto-bold',
        color: '#646777',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        overflowWrap: 'break-word',
        fontWeight: 'bold'
    },
    symptTableCellTime: {
        fontSize: 12,
        flexGrow: 0,
        minWidth: 65,
        maxWidth: 65,
        padding: '10',
        fontFamily: 'Roboto-regular',
        color: '#646777',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        overflowWrap: 'break-word'
    },
    symptTableCellTimeHeader: {
        fontSize: 12,
        flexGrow: 0,
        minWidth: 65,
        maxWidth: 65,
        padding: '10',
        fontFamily: 'Roboto-bold',
        color: '#646777',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        overflowWrap: 'break-word',
        fontWeight: 'bold'
    },
    symptTableCellTemp: {
        fontSize: 12,
        flexGrow: 0,
        minWidth: 50,
        maxWidth: 50,
        padding: '10',
        fontFamily: 'Roboto-regular',
        color: '#646777',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        overflowWrap: 'break-word'
    },
    symptTableCellTempHeader: {
        fontSize: 12,
        flexGrow: 0,
        minWidth: 50,
        maxWidth: 50,
        padding: '10',
        fontFamily: 'Roboto-bold',
        color: '#646777',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        overflowWrap: 'break-word',
        fontWeight: 'bold'
    },
    symptTableCellCount: {
        fontSize: 12,
        flexGrow: 0,
        minWidth: 100,
        maxWidth: 100,
        padding: '10',
        fontFamily: 'Roboto-regular',
        color: '#646777',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        overflowWrap: 'break-word'
    },
    symptTableCellCountHeader: {
        fontSize: 12,
        flexGrow: 0,
        minWidth: 100,
        maxWidth: 100,
        padding: '10',
        fontFamily: 'Roboto-bold',
        color: '#646777',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        overflowWrap: 'break-word',
        fontWeight: 'bold'
    },
    infoPage: {
        padding: '0',
        maxWidth: 275,
        margin: 'auto',
        marginTop: 0
    },
    infoPageHeader: {
        fontSize: 12,
        fontFamily: 'Roboto-bold',
        color: '#646777',
        fontWeight: 'bold',
        width: '33.3%',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: '#eff1f5',
        padding: 10
    },
    infoPageCell: {
        fontSize: 12,
        fontFamily: 'Roboto-regular',
        color: '#646777',
        width: '33.3%',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#eff1f5',
        padding: '7px 10px'
    },
    image: {
        width: 20,
        height: 20,
    },
    tableHeaderRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#eff1f5',
        marginBottom: 20,
    },
    pageHeaderText: {
        fontSize: 12,
        fontFamily: 'Roboto-regular',
        color: '#646777',
        lineHeight: '1.5'
    },
    pageHeaderTextBold: {
        fontSize: 12,
        color: '#646777',
        display: 'block',
        fontWeight: 'bold',
        fontFamily: 'Roboto-bold',
    },
    tableHeaderCell: {
        padding: '10',
    },
    pageNumbers: {
        fontFamily: 'Roboto-regular',
        fontSize: 11,
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 10,
        textAlign: 'right'
    },
    title: {
        fontWeight: 'bold',
        fontFamily: 'Roboto-bold',
        fontSize: 13,
        textAlign: 'center',
        marginBottom: 20,
    },
    tableHeaderRowTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        borderBottomWidth: '1',
        borderBottomStyle: 'solid',
        borderBottomColor: '#eff1f5',
        borderLeftWidth: 1,
        borderLeftStyle: 'solid',
        borderLeftColor: '#eff1f5',
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#eff1f5'
    },
});

export const MyDoc = ({data, tableData, antipyreticData, worseningData}) => {
    function icon(action) {
        let icon;
        switch (action) {
            case 'MISSED':
                icon = times;
                break;
            case 'COMPLETE':
                icon = check;
                break;
            case 'AWAITING':
                icon =  file_grey;
                break;
            case 'ACTIVE':
                icon =  file_green;
                break;
            case 'HEAL':
                icon =  smile;
                break;
            default:
                icon = file_grey;
        }

        return icon;
    }

    return (
    <Document >
        <Page wrap size={"A4"}>
            <View style={{padding: '50 50 0'}}>
                <View style={styles.tableHeaderRowTitle}>
                    <View style={styles.tableHeaderCell}>
                        <Text style={styles.pageHeaderText}>Центр №: <Text style={styles.pageHeaderTextBold}>{getObjectData(() => data[0].center)}</Text></Text>
                    </View>
                    <View style={styles.tableHeaderCell}>
                        <Text style={styles.pageHeaderText}>Номер пациента, присвоенный ИГС:
                            <Text style={styles.pageHeaderTextBold}> {getObjectData(() => data[0].num)}</Text>
                        </Text>
                    </View>
                </View>
                <Text style={styles.title}>Электронный дневник пациента № {data[0].num}</Text>
                <View style={styles.infoPage}>
                    <View style={styles.tableRow}>
                        <Text style={styles.infoPageHeader}>День</Text>
                        <Text style={styles.infoPageHeader}>Утро</Text>
                        <Text style={styles.infoPageHeader}>Вечер</Text>
                    </View>
                    {
                        tableData && tableData.map(item => (
                            <View key={item.day} style={styles.tableRow}>
                                <Text style={styles.infoPageCell}>{item.day}</Text>
                                <Text style={styles.infoPageCell}>
                                    <Image style={styles.image} src={icon(item.morning_status)}/>
                                </Text>
                                <Text style={styles.infoPageCell}>
                                    <Image style={styles.image} src={icon(item.evening_status)}/>
                                </Text>
                            </View>
                        ))
                    }
                </View>
            </View>
        {data && data.map(item => (
            <View key={`${item.day}`} style={styles.page} break>
                <View style={styles.tableHeaderRow}>
                    <View style={styles.tableHeaderCell}>
                        <Text style={styles.pageHeaderText}>Центр №: <Text style={styles.pageHeaderTextBold}>{getObjectData(() => data[0].center)}</Text></Text>
                    </View>
                    <View style={styles.tableHeaderCell}>
                        <Text style={styles.pageHeaderText}>Номер пациента, присвоенный ИГС:
                            <Text style={styles.pageHeaderTextBold}> {getObjectData(() => data[0].num)}</Text>
                        </Text>
                    </View>
                </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCellHeader}>
                            <Text style={styles.tableCellHeaderText}>
                                День
                                <Text style={styles.tableCellHeaderTextBold}> {item.day}</Text>
                            </Text>
                            <Text style={styles.tableCellHeaderTextBold}>{getObjectData(() => item.symp_date)}</Text>
                        </View>
                        <Text style={styles.tableCellHeader}>Утро</Text>
                        <Text style={styles.tableCellHeader}>Вечер</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Температура тела</Text>
                        <Text style={styles.tableCell}>{item.mon_temp}</Text>
                        <Text style={styles.tableCell}>{item.eve_temp}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Время измерения</Text>
                        <Text style={styles.tableCell}>{item.mon_time}</Text>
                        <Text style={styles.tableCell}>{item.eve_time}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Головная боль</Text>
                        <Text style={styles.tableCell}>{item.mon_qs1}</Text>
                        <Text style={styles.tableCell}>{item.eve_qs1}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Озноб</Text>
                        <Text style={styles.tableCell}>{item.mon_qs2}</Text>
                        <Text style={styles.tableCell}>{item.eve_qs2}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Потливость</Text>
                        <Text style={styles.tableCell}>{item.mon_qs3}</Text>
                        <Text style={styles.tableCell}>{item.eve_qs3}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Слабость</Text>
                        <Text style={styles.tableCell}>{item.mon_qs4}</Text>
                        <Text style={styles.tableCell}>{item.eve_qs4}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Мышечная боль</Text>
                        <Text style={styles.tableCell}>{item.mon_qs5}</Text>
                        <Text style={styles.tableCell}>{item.eve_qs5}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Сонливость</Text>
                        <Text style={styles.tableCell}>{item.mon_qs6}</Text>
                        <Text style={styles.tableCell}>{item.eve_qs6}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Выделения из носа</Text>
                        <Text style={styles.tableCell}>{item.mon_qs7}</Text>
                        <Text style={styles.tableCell}>{item.eve_qs7}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Заложенность носа</Text>
                        <Text style={styles.tableCell}>{item.mon_qs8}</Text>
                        <Text style={styles.tableCell}>{item.eve_qs8}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Чихание</Text>
                        <Text style={styles.tableCell}>{item.mon_qs9}</Text>
                        <Text style={styles.tableCell}>{item.eve_qs9}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Боль в горле</Text>
                        <Text style={styles.tableCell}>{item.mon_qs10}</Text>
                        <Text style={styles.tableCell}>{item.eve_qs10}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Хрипота / охриплость голоса</Text>
                        <Text style={styles.tableCell}>{item.mon_qs11}</Text>
                        <Text style={styles.tableCell}>{item.eve_qs11}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Кашель</Text>
                        <Text style={styles.tableCell}>{item.mon_qs12}</Text>
                        <Text style={styles.tableCell}>{item.eve_qs12}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>Боль / тяжесть в груди</Text>
                        <Text style={styles.tableCell}>{item.mon_qs13}</Text>
                        <Text style={styles.tableCell}>{item.eve_qs13}</Text>
                    </View>
            </View>
        ))}
        <View style={{padding: '50 50 0'}} break>
            <View style={styles.tableHeaderRow}>
                <View style={styles.tableHeaderCell}>
                    <Text style={styles.pageHeaderText}>Центр №: <Text style={styles.pageHeaderTextBold}>{getObjectData(() => data[0].center)}</Text></Text>
                </View>
                <View style={styles.tableHeaderCell}>
                    <Text style={styles.pageHeaderText}>Номер пациента, присвоенный ИГС:
                        <Text style={styles.pageHeaderTextBold}> {getObjectData(() => data[0].num)}</Text>
                    </Text>
                </View>
            </View>
                <View style={styles.header}>
                    <Text style={styles.text}>
                        Прием жаропонижающего препарата
                    </Text>
                </View>
                <View style={styles.tableRow}>
                    <Text style={styles.symptTableCellIndex}/>
                    <Text style={styles.symptTableCellDateHeader}>Дата</Text>
                    <Text style={styles.symptTableCellTimeHeader}>Время</Text>
                    <Text style={styles.symptTableCellTempHeader}>°Т</Text>
                    <Text style={styles.symptTableCellHeader}>Торговое наименование</Text>
                    <Text style={styles.symptTableCellCountHeader}>Принятое количество</Text>
                </View>
                {antipyreticData && antipyreticData.map((item, index) => (
                    <View key={`${item.id}`} style={styles.tableRow} wrap={false}>
                        <Text style={styles.symptTableCellIndex}>{index + 1}</Text>
                        <Text style={styles.symptTableCellDate}>{item.cmdate}</Text>
                        <Text style={styles.symptTableCellTime}>{item.cmtime}</Text>
                        <Text style={styles.symptTableCellTemp}>{item.cmtemp}</Text>
                        <Text style={styles.symptTableCell}>{item.cmname}</Text>
                        <Text style={styles.symptTableCellCount}>{item.cmdose}</Text>
                    </View>
                ))}
        </View>
        <View style={{padding: '50 50 0'}} break>
            <View style={styles.tableHeaderRow}>
                <View style={styles.tableHeaderCell}>
                    <Text style={styles.pageHeaderText}>Центр №: <Text style={styles.pageHeaderTextBold}>{getObjectData(() => data[0].center)}</Text></Text>
                </View>
                <View style={styles.tableHeaderCell}>
                    <Text style={styles.pageHeaderText}>Номер пациента, присвоенный ИГС:
                        <Text style={styles.pageHeaderTextBold}> {getObjectData(() => data[0].num)}</Text>
                    </Text>
                </View>
            </View>
                <View style={styles.header}>
                    <Text style={styles.text}>
                        Ухудшение состояния
                    </Text>
                </View>
                <View style={styles.tableRow}>
                    <Text style={styles.symptTableCellIndex}/>
                    <Text style={styles.symptTableCellDate}>Дата</Text>
                    <Text style={styles.symptTableCellTime}>Время</Text>
                    <Text style={styles.symptTableCell}>Описание</Text>
                </View>
                {worseningData && worseningData.map((item, index) => (
                    <View key={`${item.id}`} style={styles.tableRow} wrap={false}>
                        <Text style={styles.symptTableCellIndex}>{index + 1}</Text>
                        <Text style={styles.symptTableCellDate}>{item.aedate}</Text>
                        <Text style={styles.symptTableCellTime}>{item.aetime}</Text>
                        <Text style={styles.symptTableCell}>{item.aedesc}</Text>
                    </View>
                ))}
        </View>
            <Text style={styles.pageNumbers} render={({pageNumber, totalPages}) => (
                `${pageNumber} / ${totalPages}`
            )} fixed/>
        </Page>
    </Document>
)};
