import * as React from 'react';
import {PureComponent} from 'react';
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "reactstrap";
import classnames from "classnames";
import propTypes from 'prop-types';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {fetchSubjectSympData} from "../../../redux/actions/subject/subjectSympAction";
import {Field, formValueSelector, reduxForm} from "redux-form";

//components
import SubjectForm001 from './forms/SubjectForm001';
import SubjectForm002 from './forms/SubjectForm002';
import Panel from "../../_commonComponents/Panel";
import renderInput from "../../_commonComponents/form/HorizontalInputValidation";
import IconButton from "../../_commonComponents/buttons/IconButton";
import Modal from "../../_commonComponents/Modal";

//utils
import {getObjectData} from "../../../utils/getObjectData";

//constants
import {SCROLL_LIMIT, SUBJECT_HOME_PATH} from "../../../config/constants";

const validate = (values) => {
    const errors = {};

    if (values.temp < 34 || values.temp > 44) {
        errors.temp = 'Введите корректную температуру'
    } else if (values.temp && values.temp.length < 4) {
        errors.temp = 'Введите корректную температуру'
    }

    if (values.time && !/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/.test(values.time)) {
        errors.time = 'Укажите время в 24-ом формате';
    } else if (values.time && values.time.length < 5) {
        errors.time = 'Укажите время в 24-ом формате';
    }

    return errors;
};

const Title = React.forwardRef((props, ref) => {
    return (
        <section style={{position: 'absolute', top: 0}} ref={ref}/>
    );
});

/**
 * Симптомы ОРВИ - общий компонент для формы страница пациента, и страницы администрирования
 */
class SubjectSymp extends PureComponent {
    static propTypes = {
        daynum: propTypes.string.isRequired,
        readOnly: propTypes.bool,
        initialValues: propTypes.object,
        daypart: propTypes.string.isRequired,
        isEmptyValue: propTypes.bool,
    };

    static defaultProps = {
        readOnly: false,
        isEmptyValue: false
    };

    constructor(props) {
        super(props);
        this.scrollRef = React.createRef();

        this.state = {
            isOpen: false,
            isClosePopupOpen: false,
            windowTopPosition: 0,
            isMobile: false,
            formArray: []
        }
    };

    componentDidMount() {
        this.getWindowTopPosition();
        window.addEventListener('scroll', this.getWindowTopPosition);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.getWindowTopPosition);
    }

    getWindowTopPosition = () => {
        const windowTopPosition = window.pageYOffset;

        this.setState({
            windowTopPosition
        });
    };

    onSubmit = async (values) => {
        const {
            daynum,
            daypart,
            fetchSubjectSympData
        } = this.props;

        await fetchSubjectSympData({...values, daypart, daynum});
        this.onClose();
    };

    togglePopup = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }))
    };

    scrollToContent = () => {
        this.scrollRef.current.scrollIntoView({behavior: 'smooth'});
    };

    toggleClosePopup = () => {
        const {
            history,
            readOnly
        } = this.props;

        if (history && readOnly) {
            this.props.history.push(SUBJECT_HOME_PATH)
        } else {
            this.setState(prevState => ({
                isClosePopupOpen: !prevState.isClosePopupOpen
            }))
        }
    };

    onClose = () => {
        this.props.history.push(SUBJECT_HOME_PATH)
    };

    get daypart() {
        const {
            daypart
        } = this.props;

        return daypart === '1' ? 'Утро' : 'Вечер';
    }

    get createTime() {
        let time = getObjectData(() => this.props.initialValues.date_time_create_text);
        time = time && time.split(' ')[1];
        return time ? time : '';
    }

    static get fieldsLabelObject001() {
        return {
            temp: 'Температура тела',
            time: 'Время измерения',
            qs1: 'Головная боль',
            qs2: 'Озноб',
            qs3: 'Потливость',
            qs4: 'Слабость',
            qs5: 'Мышечная боль',
            qs6: 'Сонливость',
            qs7: 'Выделения из носа',
            qs8: 'Заложенность носа',
            qs9: 'Чихание',
            qs10: 'Боль в горле',
            qs11: "Хрипота / \n" +
                " охриплость голоса:",
            qs12: 'Кашель',
            qs13: "Боль / \n" +
                " тяжесть в груди",
        };
    };

    static get fieldsLabelObject002() {
        return {
            temp: 'Температура тела',
            time: 'Время измерения',
            qs1: 'Головная боль',
            qs2: 'Лихорадка / Озноб',
            qs3: 'Мышечная / суставная боль',
            qs4: 'Слабость / Сонливость',
            qs5: 'Кашель',
            qs6: 'Боль в горле',
            qs7: 'Чихание',
        }
    }

    openSubmitPopup = () => {
        const {
            formValue,
            theme
        } = this.props;

        let formArray = [];
        const statusObject = theme === 'blue' ? SubjectSymp.fieldsLabelObject001 : SubjectSymp.fieldsLabelObject002;

        for (let key in formValue) {
            if (formValue.hasOwnProperty(key)) {
                if (!formValue[key]) {
                    formArray.push(statusObject[key])
                }
            }
        }

        this.setState({
            formArray: formArray.join(', ')
        }, () => {
            this.togglePopup();
        })
    };

    isValueEmpty = (name) => {
        const {
            formArray,
        } = this.state;

        const {
            isEmptyValue,
            formValue
        } = this.props;

        return isEmptyValue ? isEmptyValue : (formArray.length > 0 ? !formValue[name] : false)
    };

    render() {
        const {
            theme,
            title,
            readOnly,
            daynum,
            handleSubmit,
            history
        } = this.props;

        const {
            isOpen,
            isClosePopupOpen,
            windowTopPosition,
            formArray,
        } = this.state;

        return (
            <form className="form">
                {!readOnly && <Title ref={this.scrollRef} />}
               <div className={"d-flex justify-content-end align-content-center"}>
                   {history && <IconButton icon={"home"} onClick={this.toggleClosePopup} color={"secondary"} className="mr-2 mb-3"/>}
                    {!readOnly &&<Button onClick={this.openSubmitPopup} color={"primary"} className={"mb-3"}>Подтвердить</Button>}
                </div>
                <div>
                    <Panel className={
                        classnames("subjectSymp__block", readOnly ? "subjectSymp__block_padding" : "")}
                           title={title}
                           header
                           subhead={`День ${daynum} - ${this.daypart} ${this.createTime}`}
                    >
                        <Field
                            name={"temp"}
                            component={renderInput}
                            label={"Температура тела:"}
                            type={"tel"}
                            mask={"99.9"}
                            placeholder={"__ __ . __"}
                            readOnly={readOnly}
                            icon={"°С"}
                            isEmptyValue={this.isValueEmpty('temp')}
                        />
                        <Field
                            name={"time"}
                            component={renderInput}
                            label={"Время измерения:"}
                            type={"tel"}
                            mask={"99:99"}
                            placeholder={"__ __ : __ __"}
                            readOnly={readOnly}
                            icon={<ClockOutlineIcon/>}
                            isEmptyValue={this.isValueEmpty('time')}
                        />
                    </Panel>
                    {theme === 'blue'
                        ? <SubjectForm001
                            readOnly={readOnly}
                            isValueEmpty={this.isValueEmpty}
                            labelObject={SubjectSymp.fieldsLabelObject001}
                        />
                        : <SubjectForm002
                            readOnly={readOnly}
                            isValueEmpty={this.isValueEmpty}
                            labelObject={SubjectSymp.fieldsLabelObject002}
                        />
                    }
                </div>
                {!readOnly && <Modal
                    color={formArray.length > 0 ? "danger" : "primary"}
                    isOpen={isOpen}
                    onSubmit={formArray.length > 0 ? this.togglePopup : handleSubmit(this.onSubmit)}
                    toggle={formArray.length > 0 ? handleSubmit(this.onSubmit) : this.togglePopup}
                    closePopup={this.togglePopup}
                    header
                    title={"Пожалуйста, подтвердите сохранение данных"}
                    message={formArray.length > 0 ? `Следующие поля не заполнены: ${formArray}` : 'После сохранения данные будут недоступны для изменения'}
                    closeText={formArray.length > 0 ? "Все равно сохранить" : "Отмена"}
                    submitText={formArray.length > 0 ? "Вернуться и заполнить" : "Подтвердить"}
                />}
                {!readOnly && <Modal
                    color={"danger"}
                    isOpen={isClosePopupOpen}
                    toggle={this.toggleClosePopup}
                    header
                    onSubmit={this.onClose}
                    submitText={"Подтвердить"}
                    title={"Вы уверены, что хотите закрыть форму?"}
                    message={"Все несохраненные данные будут потеряны"}
                />}
                {!readOnly && <div className="d-block d-xl-none">
                    <Button
                        color="primary"
                        className={classnames('btn-scroll p-3', windowTopPosition > SCROLL_LIMIT ? 'btn-scroll_show' : '')}
                        onClick={this.scrollToContent}
                    >
                        <FontAwesomeIcon color="white" icon="chevron-up"/>
                    </Button>
                </div>}
            </form>
        )
    }
}

const selector = formValueSelector('subject_symp_form');

function formValue(theme, store) {
    return theme === 'blue'
        ? {
            temp: selector(store, 'temp'),
            time: selector(store, 'time'),
            qs1: selector(store, 'qs1'),
            qs2: selector(store, 'qs2'),
            qs3: selector(store, 'qs3'),
            qs4: selector(store, 'qs4'),
            qs5: selector(store, 'qs5'),
            qs6: selector(store, 'qs6'),
            qs7: selector(store, 'qs7'),
            qs8: selector(store, 'qs8'),
            qs9: selector(store, 'qs9'),
            qs10: selector(store, 'qs10'),
            qs11: selector(store, 'qs11'),
            qs12: selector(store, 'qs12'),
            qs13: selector(store, 'qs13'),
        }
        : {
            temp: selector(store, 'temp'),
            time: selector(store, 'time'),
            qs1: selector(store, 'qs1'),
            qs2: selector(store, 'qs2'),
            qs3: selector(store, 'qs3'),
            qs4: selector(store, 'qs4'),
            qs5: selector(store, 'qs5'),
            qs6: selector(store, 'qs6'),
            qs7: selector(store, 'qs7'),
        }
}

const mapStateToProps = (store, props) => ({
    theme: store.settings.theme,
    title: store.settings.symptFormTitle,
    formValue: formValue(store.settings.theme, store),
    initialValues: props.initialValues,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSubjectSympData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    enableReinitialize: true,
    form: 'subject_symp_form',
    touchOnChange: true,
    validate
})(SubjectSymp));
